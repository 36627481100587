import { CURRENCY, EVENTFORMAT } from '~/utils/enums';
import { uppercase, roundPrice, firstLetterUppercase } from '@/utils/helpers/string';
export default {
  data: () => ({
    uppercase,
    roundPrice,
    firstLetterUppercase,
    CURRENCY,
    EVENTFORMAT
  }),

  methods: {
    calculatedPromotion(price, discount, currency) {
      const finishPrice = price * ((100 - discount) / 100);
      return finishPrice === 0
        ? this.uppercase(this.$t('ui.label.free'))
        : this.roundPrice(finishPrice) + CURRENCY[currency];
    }
  }
};
