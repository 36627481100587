<template>
  <!-- Price course when user is not enroll -->
  <div class="pt-price">
    <icon v-if="icon" name="pt-pricetag-alt" role="promotion" :class="`pt-price--${theme}__icon`" />
    <span :class="[{ 'line-through ': discount && discount > 0 }, `pt-price--${theme}__default`]">
      {{
        price === 0 || !price
          ? firstLetterUppercase($t('ui.label.free'))
          : roundPrice(price) + CURRENCY[currency]
      }}
    </span>
    <span v-if="discount && discount > 0" :class="`pt-price--${theme}__discount`">{{
      calculatedPromotion(price, discount, currency)
    }}</span>
  </div>
</template>

<script>
import PriceCourse from '~/mixins/PriceCourse';

export default {
  name: 'Price',
  mixins: [PriceCourse],

  props: {
    currency: {
      type: String,
      default: ''
    },
    textXl: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    icon: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'red',
      validator: val => ['orange', 'red'].includes(val)
    }
  }
};
</script>
<style lang="scss" src="./style.scss" />
